import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, useStaticQuery } from 'gatsby'
import BlockText from '../components/block-text'

const TermsAndConditions = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityTermsAndConditions {
        id
        title
        _rawText
      }
    }
  `)

  const { title, _rawText } = data.sanityTermsAndConditions
  //const page = data.prismicTermsAndConditions.data
  //const title = page.terms_and_conditions_heading.raw[0].text
  //const termsText = page.terms_and_conditions_text.html

  return (
    <Layout>
      <SEO title={title} />
      <div className="container-theme">
        <div className="container-fluid">
          <section className="terms-and-conditions">
            <div className="process-top-intro col-md-4 col-sm-5 offset-3 col-6-mob offset-0-mob">
              <h1 className="process-title">{title}</h1>
              <BlockText blocks={_rawText} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditions
